// Generated by Framer (3944b55)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/a3zcn4WMZ";
import * as sharedStyle1 from "../css/Y2kolLxke";

const cycleOrder = ["SEcBcrQcT", "QaA7A8P2H"];

const variantClassNames = {QaA7A8P2H: "framer-v-1jps59e", SEcBcrQcT: "framer-v-zzbwhm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1 ": "SEcBcrQcT", "Variant 2": "QaA7A8P2H"}

const getProps = ({clientName, height, id, width, year, ...props}) => { return {...props, dGMr8VprM: clientName ?? props.dGMr8VprM ?? "Client Name", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "SEcBcrQcT", waBaHUZJR: year ?? props.waBaHUZJR ?? "Year"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;clientName?: string;year?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, dGMr8VprM, waBaHUZJR, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "SEcBcrQcT", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-LpIXY", sharedStyle.className, sharedStyle1.className, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-zzbwhm", className)} data-border data-framer-name={"Variant 1 "} layoutDependency={layoutDependency} layoutId={"SEcBcrQcT"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgb(34, 34, 34)", "--border-left-width": "0px", "--border-right-width": "0px", "--border-style": "solid", "--border-top-width": "0px", ...style}} variants={{QaA7A8P2H: {"--border-top-width": "1px"}}} {...addPropertyOverrides({QaA7A8P2H: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 className={"framer-styles-preset-tu5jt"} data-styles-preset={"a3zcn4WMZ"} style={{"--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-1of0zx5, rgb(0, 0, 0))"}}>Brand Content</motion.h2></React.Fragment>} className={"framer-kyaqdq"} layoutDependency={layoutDependency} layoutId={"D2oiN9_si"} style={{"--extracted-1of0zx5": "rgb(0, 0, 0)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={dGMr8VprM} verticalAlignment={"top"} withExternalLayout/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-ze51b1"} data-styles-preset={"Y2kolLxke"} style={{"--framer-text-color": "var(--extracted-r6o4lv, rgb(0, 0, 0))"}}>Year</motion.p></React.Fragment>} className={"framer-xmig62"} layoutDependency={layoutDependency} layoutId={"Y4dey4nCk"} style={{"--extracted-r6o4lv": "rgb(0, 0, 0)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={waBaHUZJR} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-LpIXY [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-LpIXY .framer-1u3erzq { display: block; }", ".framer-LpIXY .framer-zzbwhm { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; height: 32px; justify-content: space-between; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 1160px; }", ".framer-LpIXY .framer-kyaqdq, .framer-LpIXY .framer-xmig62 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ...sharedStyle.css, ...sharedStyle1.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 1160
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"QaA7A8P2H":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"dGMr8VprM":"clientName","waBaHUZJR":"year"}
 * @framerImmutableVariables false
 */
const FramernoNVcEAQu: React.ComponentType<Props> = withCSS(Component, css, "framer-LpIXY") as typeof Component;
export default FramernoNVcEAQu;

FramernoNVcEAQu.displayName = "Clients";

FramernoNVcEAQu.defaultProps = {height: 32, width: 1160};

addPropertyControls(FramernoNVcEAQu, {variant: {options: ["SEcBcrQcT", "QaA7A8P2H"], optionTitles: ["Variant 1 ", "Variant 2"], title: "Variant", type: ControlType.Enum}, dGMr8VprM: {defaultValue: "Client Name", displayTextArea: false, title: "Client Name", type: ControlType.String}, waBaHUZJR: {defaultValue: "Year", displayTextArea: false, placeholder: "", title: "Year", type: ControlType.String}} as any)

addFonts(FramernoNVcEAQu, [...sharedStyle.fonts, ...sharedStyle1.fonts])